import { initializeApp } from 'firebase/app';
import { type Analytics, getAnalytics, isSupported } from 'firebase/analytics';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';

// @TODO disabled for now, check initializeAppCheck below for additional details
// import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

const firebaseConfig = {
  apiKey: 'AIzaSyDSSJrAXWH4cNKRSSHoejE2SuYzTCZXSSY',
  authDomain: 'pointpoker.xyz',
  databaseURL: 'https://point-poker-2cafd-default-rtdb.firebaseio.com',
  projectId: 'point-poker-2cafd',
  storageBucket: 'point-poker-2cafd.appspot.com',
  messagingSenderId: '12728179155',
  appId: '1:12728179155:web:6a242c6d3ebc60e0183726',
  measurementId: 'G-0WLEKTRTQ6'
};

// tracks if in local dev for emulation purposes
const isLocalDev = typeof window !== 'undefined' && window.location.hostname === 'localhost';

// setup app
const app = initializeApp(firebaseConfig);

// @TODO disabled due to 3rd party cookie issues (google needs to resolve). Also not enough traffic so causes false positives
// reCAPTCHA setup (client side only)
// if (typeof window !== 'undefined' && !isLocalDev) {
//   initializeAppCheck(app, {
//     provider: new ReCaptchaEnterpriseProvider('6Ld1RiIpAAAAALlX18Qb-YLK5bVQHQ-P6yGEe58-'),
//
//     // Optional argument. If true, the SDK automatically refreshes App Check
//     // tokens as needed.
//     isTokenAutoRefreshEnabled: true
//   });
// }

// setup auth
const auth = getAuth(app);
if (isLocalDev) {
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
}

// setup db
const db = getDatabase(app);
if (isLocalDev) {
  connectDatabaseEmulator(db, 'localhost', 9000);
}

// setup analytics (prevent failures in unsupported states)
let analytics: Analytics;
isSupported().then((isAnalyticsSupported) => {
  if (isAnalyticsSupported) {
    analytics = getAnalytics(app);
  }
});

export { analytics, auth, db };
